<template>
  <div class="">
<HeaderVue   back="/manage/products" heading="Add Product"></HeaderVue>
  <div class="" >
   
    <div
      class="mb-5"
    
    >
      <div class="row p-3">
        <div class="col-12">
          <div class="">
         

            <div >
              <div class="row card-body text-left p-0">
                <!-- Center Section Start -->
                <div class="col-12 col-sm-12 col-md-12 col-lg-8 mt-3 mt-lg-0">
                  <!-- Center  Section Start -->
                  <div class="row card border-radius py-3">
                    <!-- Inner Content Start -->
                   <div
                        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      >
                        <!-- Title Start -->
                        <div
                          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <p class="headText m-0 mb-1">
                            Title <span style="color: red">*</span>
                          </p>
                          <b-form-input
                            v-model="newProduct.title"
                            class=" "
                          
                          ></b-form-input>
                        </div>
                        <!-- Title End -->
                        <!-- Description Start -->
                        <div
                          class="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <p class="headText m-0 mb-1">Description</p>
                         
                        
                        <Editor style="border-bottom:1px solid var(--border-color)" :productDescription="newProduct.description" placeholder="Write Something"  @updateDescription="updateProductDescription($event)"/>
                        </div>
                        <!-- Description End -->
                        <!-- Price Start -->
                        <div class="mt-3 col-12">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-4">
                              <p class="headText m-0 mb-1">Price</p>
                              <b-form-input
                                placeholder="00.00"
                                class=""
                                type="number"
                                v-model="newProduct.price"
                              ></b-form-input>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 mt-2 mt-md-0">
                              <p class="headText m-0 mb-1">Compared Price</p>
                              <b-form-input
                                placeholder="00.00"
                                type="number"
                                v-model="newProduct.compare_at_price"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                        <!-- Price End -->
                      </div>
                  </div>
    <ProductMediaCard class="row flex-column mt-3" :uploadImageLink="uploadImageLink" :images="newProduct.productImages" @OpenImageUrlModal="OpenImageUrlModal" :isLoadImageAdding="isImageAdding" :isLoadingDeleteImage="isImageDelete" @selectedImages="(e)=>selectedProductImage=e" @updateImageDrag="(e)=>newProduct.productImages=e" @delete="deleteImages()" :uploadImage=uploadFile></ProductMediaCard>

                  <div
                class="row mt-3"
                style="
                  border: 1px solid rgba(0, 0, 0, 0.125);
                  min-height: 350px;
                "
                v-if="false"
              >
              <div class="col-12">
                <p class="headText m-0 mb-2">Media</p>
              </div>
                <div
                  class="col-12  d-flex justify-content-between align-items-center"
                  v-if="selectedProductImage.length"
                >
                  <div>
                    <b-form-checkbox
                      id="SelectAllImage"
                      @change="selectAllImage($event)"
                      name="SelectAllImage"
                    >
                      Select All Images
                    </b-form-checkbox>
                  </div>
                  <div>
                    <button
                      v-if="selectedProductImage.length == 1"
                      class="btn text-success"
                      @click="MakeThumbnailImage"
                    >
                      Make Thumbnail
                    </button>
                    <button class="btn text-danger" @click="deleteImages">
                     <span class="text-danger" v-if="!isImageDelete">Delete</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                    </button>
                  </div>
                </div>
                <!-- Main Product Image start  -->
                <div
                  class="col-12 col-md-6 col-lg-6 col-xl-4"
                  v-if="getFirstImage"
                >
                  <div
                    class="card border-radius ImageCard shadow-sm d-flex align-items-center justify-content-center"
                    style="height: 350px; width: 100%; position: relative"
                  >
                
                    <LazyImage
                      :key="`${getFirstImage.id}`"
                      :src="GetImageSrc(getFirstImage)"
                      style="
                        width: 90%;
                        height: 90%;
                        object-fit: cover;
                        border-radius: 6px;
                      "
                      alt=""
                    />
                    <b-form-checkbox-group
                      class="checkboxElement"
                      :id="`ProductImageCheck-${getFirstImage.id}`"
                      style="position: absolute; top: 5%; left: 6%"
                      :style="
                        selectedProductImage.length
                          ? 'display:block !important;'
                          : null
                      "
                      v-model="selectedProductImage"
                      :name="`ProductImageCheck-${getFirstImage.id}`"
                    >
                      <b-form-checkbox
                        :value="getFirstImage"
                      ></b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </div>
                <!-- Main Product Image end  -->
                <!-- Other Images Start -->
                <div class="col-12 col-md-6 col-lg-6 col-xl-8 p-0">
                  <!-- Other Images -->
                  <div class="row m-0 mt-3">
                   
                    <div
                      class="col-6 col-md-6 col-lg-6 col-xl-4 mb-2"
                      :class="`m`"
                      v-for="(file, index) in newProduct.productImages"
                      :key="index"
                      v-show="newProduct.productImages.length && file !== getFirstImage"
                    >
                      <div
                        class="card border-radius ImageCard shadow-sm otherImageDiv d-flex align-items-center justify-content-center"
                        style="
                          border-radius: 6px;
                          cursor: pointer;
                          height: 150px;
                          width: 150px;
                          position: relative;
                        "
                      >
                        <!-- <video
                            v-if="checkFileType(image.image) == 'video'"
                            ref="videoRef"
                            :src="`${image.image}`"
                            style="object-fit: cover; border-radius: 6px"
                            width="90%"
                            height="90%"
                            controls
                          ></video> -->
                        <LazyImage
                          :key="`${file.id}`"
                          :src="GetImageSrc(file)"
                          style="
                            width: 90%;
                            height: 90%;
                            object-fit: cover;
                            border-radius: 6px;
                          "
                          alt=""
                        />
                        <b-form-checkbox-group
                          class="checkboxElement"
                          :id="`ProductImageCheck-${file.id}`"
                          style="position: absolute; top: 5%; left: 6%"
                          :style="
                            selectedProductImage.length
                              ? 'display:block !important;'
                              : null
                          "
                          v-model="selectedProductImage"
                          :name="`ProductImageCheck-${file.id}`"
                        >
                          <b-form-checkbox :value="file"></b-form-checkbox>
                        </b-form-checkbox-group>
                      </div>
                    </div>
                    <!-- Add Image Start -->
                     
                    <div class="col-6 col-md-6 col-lg-6 col-xl-4">
                     
                      <div
                        class="card border-radius shadow-sm otherImageDiv"
                        style="
                          border-radius: 6px;
                          color: #cbc6cf;
                          height: 150px;
                          width: 150px;
                        "
                      >
                        <div
                          v-if="!isImageAdding"
                          class="d-flex flex-column align-items-center justify-content-around h-100"
                        >
                          <!-- ADD start -->
                          <div class="d-flex flex-column">
                            <button
                              class="btn text-white"
                              style="background: #4d1b7e"
                              @click="$refs.ImageFileInput.click()"
                            >
                              <span style="font-weight: 600">Add Image</span>
                            </button>
                            <input
                              type="file"
                              accept="image/*;video/*"
                              style="display: none"
                              ref="ImageFileInput"
                              multiple
                              @change="uploadFile"
                            />
                          </div>
                          <!-- Add End -->
                          <!-- ADD URL start -->
                          <div class="">
                            <button class="btn" @click="OpenImageUrlModal">
                              <span
                                style="
                                  color: #4d1b7e;
                                  font-weight: 600;
                                  text-decoration: underline;
                                "
                                >Add from URL</span
                              >
                            </button>
                          </div>
                          <!-- ADD URL start -->
                        </div>
                        <div
                          v-else
                          class="d-flex h-100 align-items-center justify-content-center"
                        >
                          <div
                            class="spinner-border spinner-border"
                            style="color: #4d1b7e"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Add Image End -->
                  </div>
                </div>
                <!-- Other Images End -->
              </div>

                  <div class="row card border-radius py-3 mt-3 d-none">
                    <div class="col-12 col-sm-6 col-md-12 text-left">
                      <!-- Options Start -->
                      <div class="mt-3 d-none">
                        <!-- checkbox Start -->
                        <div>
                          <b-form-checkbox
                            id="OptionCheck"
                            v-model="newProduct.optionCheckStatus"
                            name="OptionCheck"
                            :value="true"
                            :unchecked-value="false"
                          >
                            <span class="headText" style="color: #000000"
                              >This product has options, like size or
                              color</span
                            >
                          </b-form-checkbox>
                        </div>
                        <!-- checkbox End -->
                        <div class="mt-3" v-if="newProduct.optionCheckStatus">
                          <draggable
                            v-model="newProduct.optionsList"
                            animation="0"
                            @start="OptionDragging = true"
                            @end="OptionDragging = false"
                            style="cursor: move"
                          >
                            <div
                              class="mb-3"
                              v-for="(option, index) in newProduct.optionsList"
                              :key="index"
                            >
                              <div class="p-3 w-75 card" style="">
                                <div class="w-100">
                                  <div
                                    class="w-100 d-flex justify-content-end"
                                    v-if="newProduct.optionsList.length > 1"
                                  >
                                    <button
                                      class="btn"
                                      @click="deleteOption(index)"
                                    >
                                      <i
                                        class="text-danger fa fa-trash-o"
                                        style="font-size: 20px"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                                <div class="w-75">
                                  <div class="d-flex align-items-center">
                                    <i
                                      class="fa fa-bars mr-3"
                                      style="color: #4d4950"
                                      aria-hidden="true"
                                    ></i>
                                    <p class="headText m-0 mb-1">Option Name</p>
                                  </div>
                                  <b-form-input
                                   
                                    v-model="option.title"
                                  ></b-form-input>
                                </div>
                                <div class="mt-2 w-75">
                                  <p class="headText m-0 mb-1">Option Value</p>
                                  <draggable
                                    v-model="option.value"
                                    @start="OptionValueDragging = true"
                                    @end="OptionValueDragging = false"
                                    style="cursor: move"
                                  >
                                    <div
                                      class="mb-2 d-flex align-items-center"
                                      v-for="(
                                        optionValue, valueIndex
                                      ) in option.value"
                                      :key="valueIndex"
                                    >
                                      <i
                                        class="fa fa-bars mr-3"
                                        style="color: #4d4950"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        class="form-control"
                                       
                                        :value="optionValue.value"
                                        @change="
                                          changeOptionValue(optionValue, $event)
                                        "
                                      />
                                      <div
                                        class=""
                                        v-if="option.value.length > 1"
                                      >
                                        <button
                                          class="btn"
                                          @click="
                                            deleteOptionValue(index, valueIndex)
                                          "
                                        >
                                          <i
                                            class="fa fa-trash-o"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </draggable>
                                  <div class="mt-2">
                                    <button
                                      class="btn ml-3"
                                      @click="AddOptionsValue(index)"
                                    >
                                      <span class="AddOption"
                                        ><i
                                          class="fa fa-plus mr-2"
                                          aria-hidden="true"
                                        ></i>
                                        Add Option Value</span
                                      >
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </draggable>
                          <div class="mt-3 d-flex align-items-center">
                            <button
                              class="btn mr-5"
                              @click="AddOptions"
                              v-if="newProduct.optionsList.length < 3"
                            >
                              <span class="AddOption"
                                ><i
                                  class="fa fa-plus mr-2"
                                  aria-hidden="true"
                                ></i>
                                Add Option</span
                              >
                            </button>
                            <button
                              class="btn px-3 py-2"
                              style="background: #4d1b7e; border-radius: 6px"
                              @click="CreateVariants"
                            >
                              <span class="text-white"> Create Variants</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Options End -->
                    </div>
                  </div>
                  <div
                    class="row mt-3 card border-radius py-3"
                    v-if="
                      newProduct.optionCheckStatus && newProduct.variants.length
                    "
                  >
                    <div class="col-12 col-sm-6 col-md-12">
                      <!-- Variant Start -->
                      <div class="mt-3">
                        <b-table
                          responsive
                          :items="newProduct.variants"
                          :fields="fields"
                        >
                          <template #cell(image)="data">
                            <div
                              v-if="!data.item.image"
                              style="width: 60px; height: 60px; cursor: pointer"
                              class="d-flex align-items-center justify-content-center"
                              @click.stop="
                                OpenProductImageGallery(
                                  'VariantImage',
                                  data.index
                                )
                              "
                            >
                              <LazyImage
                                :src="'/images/image_placeholder.png'"
                                alt=""
                                style="
                                  height: 100%;
                                  width: 100%;
                                  object-fit: cover;
                                  border-radius: 6px;
                                "
                              />
                            </div>
                            <div
                              v-else
                              style="width: 50px; height: 50px; cursor: pointer"
                              class=""
                              @click.stop="
                                OpenProductImageGallery(
                                  'VariantImage',
                                  data.index,
                                  data.item.image
                                )
                              "
                            >
                              <LazyImage
                                :key="data.item.image"
                                :src="ImageSrc(data.item.image)"
                                alt=""
                                style="
                                  height: 100%;
                                  width: 100%;
                                  border-radius: 6px;
                                "
                              />
                            </div>
                          </template>
                          <template #cell(title)="data">
                            <label> {{ data.item.title }}</label>
                          </template>
                          <template #cell(price)="data">
                            <b-form-input
                              v-model="data.item.price"
                              type="number"
                              required
                              placeholder="00.00"
                            />
                          </template>
                          <template #cell(compare_at_price)="data">
                            <b-form-input
                              v-model="data.item.compare_at_price"
                              type="number"
                              required
                              placeholder="00.00"
                            />
                          </template>
                          <template #cell(action)="data">
                            <button
                              class="btn px-2 py-2 deleteProduct mx-2"
                              @click="DeleteVariant(data.row)"
                            >
                              Delete
                              <i
                                class="ml-2 fa fa-trash"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </template>
                        </b-table>
                      </div>
                      <!-- Variant End -->
                    </div>
                  </div>
                  <!-- Inner Content End -->
                </div>
                <!-- Center Section Start -->
                <!-- Right Section Start -->
                <div
                  class="col-12 col-sm-12 co col-md-4 pr-0"
                  style="height: fit-content"
                >
                  <div class="card border-radius px-3 pb-4">
                    <!-- Collections & tags Start -->
                    <div class="mt-3">
                      <div>
                        <p class="headText m-0 mb-1">Status</p>
                        <b-form-group
                          class="text-dark font-weight-bold SelectFormGroup"
                        >
                          <b-form-select v-model="newProduct.isActive" class="">
                            <b-form-select-option
                              selected
                              disabled
                              :value="null"
                            >
                              Product Status
                            </b-form-select-option>
                            <b-form-select-option :value="true"
                              >Active</b-form-select-option
                            >
                            <b-form-select-option :value="false"
                              >Disabled</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </div>
<!-- Detail Links -->
<div class="my-3">
                      <p class="headText m-0 mb-1">Link for details</p>
                      <b-form-input
                       
                        v-model="newProduct.detailLink"
                      ></b-form-input>
                    </div>
                      <!-- SKU -->
                      <div class="mt-3">
                        <p class="headText m-0 mb-1">SKU</p>
                        <b-form-input
                         
                          v-model="newProduct.sku"
                        ></b-form-input>
                      </div>

                      <!-- SKU -->
                      <!-- Quantity -->
                    <!-- <div class="mt-3 QuantityInput ">
                      <p class="headText m-0 mb-1">Quantity</p>
                      <b-form-input
                      type="number"
                      class="QuantityInputForm"
                        
                        v-model="newProduct.quantity"
                      >
                      
                        
                      </b-form-input>


                      <div class="d-flex QtyBtn flex-column">
                          <button class="btn increaseQuantityBtn font-weight-bold" @click="newProduct.quantity++">
                        <b-icon-caret-up-fill class="font-weight-bold"></b-icon-caret-up-fill>
                      </button>
                          <button class="btn decreaseQuantityBtn" @click="newProduct.quantity--">
                          <b-icon-caret-down-fill class="font-weight-bold"></b-icon-caret-down-fill>
                      </button>
                      </div>
                      

                      
                    </div> -->

                      <!-- Quantity -->
                      <div>
                        <p class="headText m-0 mt-3">Collections</p>
                         <MultiFilter :selectedItems="[]" :isLoading="isLoadingCollections" :items="allCollections" @input="(e)=>newProduct.collections=e" ></MultiFilter>
                        <!-- <b-form-tags
                          v-model="newProduct.collections"
                         
                        ></b-form-tags> -->
                      </div>
                      <div class="mt-3">
                        <p class="headText m-0 mb-1">Tags</p>
                         <MultiFilter :selectedItems="[]" :isLoading="isLoadingCollections" :addInList="true" @add="AddTag($event)" :items="allTags" @input="(e)=>newProduct.tags=[...e.map(tag=>tag.title)]" />

                       
                      </div>
                      <div class="mt-3">
                        <p class="headText m-0 mb-1">Bullet Description</p>
                         <MultiFilter :selectedItems="[]" :addInList="true" :isLoading="isLoadingCollections" :items="allBullets" @input="(e)=>newProduct.bullet_description=[...e.map(bullet=>bullet.title)]" @add="addBullet($event)"></MultiFilter>
                       
                        <!-- <b-form-tags
                          v-model="newProduct.bullet_description"
                         
                        ></b-form-tags> -->
                      </div>
                    </div>
                    <!-- Collections & tags End -->

                    <!-- Detail Links -->
                    <div class="mt-3">
                      <p class="headText m-0 mb-1">Vendor</p>
                         <!-- <MultiFilter :selectedItems="[]" :isLoading="isLoadingCollections" :items="allVendors" @input="(e)=>newProduct.vendor=[...e.map(tag=>tag.title)]" /> -->

                         <MultiFilter :singleSelect="true"  :isLoading="isLoadingCollections" :items="allVendors" @input="(e)=>newProduct.vendor=e"  />
                     
                    </div>
                    
                  </div>
                </div>
                <!-- Right Section End -->
              </div>
            </div>
            

          </div>
        </div>
      </div>
    </div>
    <EditProductFooter :isLoadingSave="isAddProductLoading"  @save="AddProduct()" :isDelete="false" :isDiscard="false"></EditProductFooter>
     <UpgradePlanModal ref="UpgradeUserPlanModalProducts" :title="upgradeModalTitle" :subText="upgradeModalsubText" @openPlansModalChild="openPlansModal"/>
  </div>
  </div>
</template>

<script>
// import useVuelidate from '@vuelidate/core'
// import { required, email } from '@vuelidate/validators'
import MultiFilter from "../Layout/MultiFilter.vue";
import draggable from "vuedraggable";
import ProductMediaCard from "./modal/ProductMediaCard.vue";
// import NavBar from "./../components/nav.vue";
import globalMixin from "../../../GlobalMixins/getSignedURLMixin.js";
import { mapGetters } from "vuex";
import axios from "axios";
import $ from "jquery";
import UpgradePlanModal from "../modal/UpgradePlanModal.vue"
import Editor from "../../../components/Editor.vue"
import HeaderVue from '../Layout/Header.vue';
import EditProductFooter from '../Layout/EditProductFooter.vue'
export default {
  setup() {
    // return { v$: useVuelidate() }
  },
  mixins: [globalMixin],
  components: {
    // NavBar,
    EditProductFooter,
    HeaderVue,
    ProductMediaCard,
    draggable,
    UpgradePlanModal,
    Editor,
    MultiFilter
    // ProductImageUrlModal
  },
  data() {
    return {
      
      isProductLoading: false,
      productImagesDragging: false,
      OptionDragging: false,
      OptionValueDragging: false,
      VariantKey: 0,
isLoadingCollections:false,
        allCollections:[],
        allTags:[],
        allBullets:[],
      newProduct: {
        isActive: true,
        image: "https://images.quizell.com/website/default_start_page.png",
        title: "",
        description: "",
        price: "",
        compare_at_price: "",
        optionCheckStatus: false,
        optionsList: [
          {
            title: "",
            value: [
              {
                value: "",
              },
            ],
          },
        ],
        
        collections: [],
        tags: [],
        bullet_description: [],
        sku: '',
        quantity: null,
        variants: [],
        vendor: "",
        detailLink: "",
        productImages: [],
      },
      fields: [
        {
          key: "image",
          label: "Image",
        },
        {
          key: "title",
          label: "Name",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "compare_at_price",
          label: "Compare Price",
        },
        {
          key: "action",
          label: "",
        },
      ],
      selectedVariantImageIndex: null,
      selectedProductImageIndex: null,
      OpenGalleryMode: "",
      selectedPropImage: "",
      EditView: "VariantView",
      isImageAdding: false,
      isAddProductLoading: false,
      selectedProductImage: [],
      ImageUrlLink: "",
      initalImages: [],
      Step2view: false,
      isAddProductStep2Loading: false,
      newlyCreatedProductId: null,
      isImageDelete:false,
       upgradeModalTitle:'Upgrade your plan to add new products!',
      upgradeModalsubText:'You have achieved your product limit, you should upgrade your plan to create new products.',
      allVendors:[],
    };
  },
  // validations () {
  //     return {
  //       newProduct: {
  //         title: { required},
  //       }
  //     }
  //   },
  methods: {
    async AddTag(data){
      let path ='/tag/save'
       let method='post'
     
     await axios[method](path,data).then(()=>{
        this.$toasted.show("Tag saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
         
      }).catch(()=>{
        this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      })
    },
  async  addBullet(data){
  
       let path ='/products/bullets'
       let method='post'
     
     await axios[method](path,data).then(()=>{
        this.$toasted.show("Bullet description saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
         
      }).catch(()=>{
        this.$toasted.show("Error occurred.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      })
     
    
    },
   async loadCollections(){
      this.isLoadingCollections=true;
        await axios.post('/createProduct').then(response=>{
          this.allCollections=response.data.data.collections
        this.allTags=response.data.data.tags
        this.allVendors=response.data.data.vendors
        this.allBullets=response.data.data.bullets
        // 
      }).catch(()=>[]);
      
       this.isLoadingCollections=false;
    },
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    async changeVariantTitle(oldVal, newVal) {
      let vm = this;
      await this.newProduct.variants.forEach(function (obj, index) {
        vm.newProduct.variants[index].title = obj.title.replace(oldVal, newVal);
      });
    },
     openPlansModal() {
      if(this.getUserType == 'web' || this.getUserType == 'bc'){
        let url = window.location.origin + `/manage/account/pricing`
            window.open(url,"_self")
      }
      else {
       this.$router.push('/')
      }
      
    },

    changeOptionValue: function (item, event) {
      this.changeVariantTitle(item.value, event.target.value);
      item.value = event.target.value;
    },
    resetAddProduct() {
      this.newProduct = {
        status: "active",
        image: "/images/image_placeholder.png",
        title: "",
        description: "",
        price: "",
        compare_at_price: "",
        optionCheckStatus: false,
        optionsList: [
          {
            title: "",
            value: [],
          },
        ],
        collections: [],
        tags: [],
        variants: [],
        vendor: "",
        detailLink: "",
        productImages: [],
      };
    },
    // UpdateProductImages(img){
    //   this.newProduct.productImages = img
    // },
    OpenProductImageGallery(openGalleryMode, index, img) {
      this.selectedVariantImageIndex = index;
      this.$refs.ProductImageModalRef.OpenImageModal(openGalleryMode, img);
    },
    // open Gallery from product Image
    openGallery(openGalleryMode, img) {
      this.$refs.ProductImageModalRef.OpenImageModal(openGalleryMode, img);
    },
    // Open gallery from other Images
    openGalleryfromOtherImages(openGalleryMode, index, img) {
      this.selectedProductImageIndex = index;
      this.$refs.ProductImageModalRef.OpenImageModal(openGalleryMode, img);
    },
    openAddMoreGallery(openGalleryMode) {
      this.$refs.ProductImageModalRef.OpenImageModal(openGalleryMode);
    },
    // update Variant Image
    SetVariantImage(image) {
      this.newProduct.variants[this.selectedVariantImageIndex].image = image;
      this.selectedVariantImageIndex = null;
    },

    // Update Thumbnail Image
    SetProductImage(image) {
      this.newProduct.image = image;
    },
    // Update Product other Image
    SetProductOtherImage(image, index) {
      this.newProduct.productImages[this.selectedProductImageIndex].image =
        image;
      this.selectedProductImageIndex = index;
    },

    ImageSrc(img) {
      return img.startsWith("/http") ? img.substring(1) : img;
    },

    AddOptions() {
      const obj = new Object();
      obj.title = "";
      obj.value = [
        {
          value: "",
        },
      ];

      this.newProduct.optionsList.push(obj);
    },
    AddOptionsValue(index) {
      const obj = {
        value: "",
      };
      this.newProduct.optionsList[index].value.push(obj);
    },
    deleteOption(index) {
      this.newProduct.optionsList.splice(index, 1);
    },
    deleteOptionValue(index, subIndex) {
      this.newProduct.optionsList[index].value.splice(subIndex, 1);
    },
    DeleteVariant(data) {
      console.log("Data", data);
    },
    CreateVariants() {
      this.VariantKey += 1;
      //  let  permittedValues = this.newProduct.optionsList.map(value => value.value);
      let permittedValues = this.newProduct.optionsList.map((value) =>
        value.value.map((x) => x.value)
      );

      const options = new Object();

      for (let i = 0; i < permittedValues.length; i++) {
        options[i] = permittedValues[i];
      }
      const results = this.getPermutations(options);
      if (results) {
        this.newProduct.variants = this.getFinalVariantArray(results);
      }
    },

    getPermutations(object, index = 0, current = {}, results = []) {
      const keys = Object.keys(object);
      const key = keys[index];
      const values = object[key];
      for (const value of values) {
        current[key] = value;
        const nextIndex = index + 1;

        if (nextIndex < keys.length) {
          this.getPermutations(object, nextIndex, current, results);
        } else {
          const result = Object.assign({}, current);
          results.push(result);
        }
      }
      return results;
    },
    getFinalVariantArray(results) {
      let Arr = [];

      for (let key in results) {
        //   if (results.hasOwnProperty(key)) {
        if (Object.prototype.hasOwnProperty.call(results, key)) {
          const obj = new Object();
          var value = results[key];
          let name = Object.keys(value).map(function (key) {
            return value[key];
          });
          obj.title = name.join("/");
          obj.price = 0.0;
          obj.compare_at_price = 0.0;
          obj.price = 0;
          obj.image = "/images/image_placeholder.png";
          obj.id = null;
          obj.product_id = null;

          Arr.push(obj);
        }
      }
      return Arr;
    },
    AllProductView() {
      //  this.$emit('AllProductView')
      // if(this.Step2view){
      //   this.Step2view = false
      // }
      // else{
      //   this.$router.push("/manage/products");
      // }
      this.$router
          .push({ path: '/manage/products' })
          .then(() => { this.$router.go(0) })
    },
  
async AddProduct() {
  try {
    // Assuming this.newProduct is initialized somewhere before this function

    // Ensure product collections, tags, and bullet_description are arrays
    this.newProduct.collections = Array.isArray(this.newProduct.collections)
      ? this.newProduct.collections
      : [];
    this.newProduct.tags = Array.isArray(this.newProduct.tags)
      ? this.newProduct.tags
      : [];
    this.newProduct.bullet_description = Array.isArray(
      this.newProduct.bullet_description
    )
      ? this.newProduct.bullet_description
      : [];
    
    this.isAddProductLoading = true;
    const form_data = new FormData();
    for (const key in this.newProduct) {
    
      if (key === "productImages") {
        form_data.append(key, JSON.stringify(this.newProduct[key]));
        for (let i = 0; i < this.newProduct[key].length; i++) {
          if (this.newProduct[key][i].externalLink) {
            form_data.append("links[]", this.newProduct[key][i].url);
          } else {
            form_data.append("files[]", this.newProduct[key][i].file);
          }
        }
      } else {
        form_data.append(
          key,
          Array.isArray(this.newProduct[key])
            ? JSON.stringify(this.newProduct[key])
            : this.newProduct[key]
        );
      }
    }
  

    this.hasErrorList = false;
    this.errorList = [];
    const response = await axios.post(`/saveQuizProduct`, form_data);

    if (response.status === 201 && response.data.status === "fail") {
      if (response.data.data === "Your Plan Products Limit Exceed") {
        this.$refs.UpgradeUserPlanModalProducts.OpenModal();
      } else {
        const Error = response.data.data;
        for (const key in Error) {
          this.$toasted.show(`${Error[key]}`, {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
      }
    } else if (response.status === 200 && response.data.status === "success") {
      this.$toasted.show("Product Created", {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
      // this.$router.push(`/manage/products`);
      this.$router
          .push({ path: '/manage/products' })
         // .then(() => { this.$router.go(0) })
    }
  } catch (error) {

  if(error.response){

    let {message} = error.response.data
    if(message){
      this.$toasted.show(message, {
      theme: "toasted-primary",
      type: "error",
      position: "bottom-center",
      duration: 2000,
    });

    return
    }

  }
    this.$toasted.show(`Error occurred`, {
      theme: "toasted-primary",
      type: "error",
      position: "bottom-center",
      duration: 2000,
    });
  } finally {
    this.isAddProductLoading = false;
  }
},
    // Image Upload Functions
    uploadFile(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = () => {
          const url = reader.result;
          this.newProduct.productImages.push({id:new Date().getTime(), type: file.type, url: url ,externalLink:false,file:file});
        };
        reader.readAsDataURL(file);
      }
    },
    
   

    async  deleteImages() {

      const Ids = this.selectedProductImage.map((x)=>x.id)
        let Arr = [...this.newProduct.productImages];
       


  for (let i = 0; i < Arr.length; i++) {
    if (Ids.includes(Arr[i].id)) {
      Arr.splice(i, 1);
      i--; 
    }
  }

  this.newProduct.productImages = Arr

    this.selectedProductImage = [];

     
    },
    MakeThumbnailImage() {
      if (this.selectedProductImage.length == 1) {
        const ID = this.selectedProductImage[0].id;
        const Arr = [...this.newProduct.productImages];
        Arr.unshift(
          Arr.splice(
            Arr.findIndex((item) => item.id === ID),
            1
          )[0]
        );

        this.newProduct.productImages = Arr;
        this.selectedProductImage = [];
      }
    },
    selectAllImage(e) {
      if (e) {
        this.selectedProductImage = this.newProduct.productImages;
      } else {
        this.selectedProductImage = [];
      }
    },

    async uploadImageLink(url) {
         if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0){
             this.newProduct.productImages.push({id:new Date().getTime(), type:'URL', url: url ,externalLink:true});

    }
    else{
         this.$toasted.show("Invalid Image Url ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
    }
      
      // this.isImageAdding = true;

      // try {
      //   this.isLink = true;
      //   let data = {
      //     product_id: this.newlyCreatedProductId,
      //     image_path: this.ImageUrlLink,
      //   };

      //   const response = await axios.post(
      //     `${process.env.VUE_APP_API_BASE_URL}storeProductImage`,
      //     data
      //   );
       
      //   if (response.status == 200 ) {
      //     this.newProduct.productImages = [...this.newProduct.productImages, response.data];
      //   }
      // } catch (error) {
      //   if (error) {
      //     this.$toasted.show("Error occured ", {
      //       theme: "toasted-primary",
      //       position: "bottom-center",
      //       duration: 2000,
      //     });
      //   }
      //   throw error;
      // } finally {
      //   this.isImageAdding = false;
      // }
    },
    OpenImageUrlModal() {
      this.ImageUrlLink = "";
      $("#AddProductURLModal").modal("show");
    },
    closeURLModal() {
      $("#AddProductURLModal").modal("hide");
    },
    GetImageSrc(file) {
      // return file.image;
      return file.url;
    },
    async saveStep2() {
      this.isAddProductStep2Loading = true;

      try {
        let data = {
          product_id: this.newlyCreatedProductId,
          image_ids: this.newProduct.productImages.map((obj) => obj.id),
        };

        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}storeProductImageOrder`,
          data
        );
        if (response.status == 200 ) {
          this.$toasted.show("Product Saved ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.AllProductView();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isAddProductStep2Loading = false;
      }
    },
     updateProductDescription(value){
      this.newProduct.description=value
    }
  },
  computed: {
     getUserType() {
      return this.getNavbarData.userType

    },
    getRandomValue() {
      return Math.floor(Math.random() * (1000 - 11 + 1) + 11);
    },
    ...mapGetters(["GetTokenFromMetaTag","getNavbarData"]),
    getFirstImage() {
      if (this.newProduct.productImages.length) {
        return this.newProduct.productImages[0];
      } else {
        return null;
      }
    },
  },
  mounted(){
    this.loadCollections()
  }
};
</script>

<style scoped>
.headTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #4b494e;
}
.headText {
  /* font-weight: 500; */
  font-size: 16px;
  color: #4d4950;
}
.subHeadText {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #18191c;
}
.AddOption {
  color: #ffa201;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

/* Edit Variant Styles */
.leftSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #4d4950;
}
.leftSection .sub-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #4d4950;
}
.leftSection .variantTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #4d4950;
}

.leftSection .VariantContainer {
  height: 100%;
  min-height: 100px;
  max-height: 350px;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.leftSection .singleVariant {
  height: 60px;
  border-radius: 6px;
  background: #e0dee2;
}
.leftSection .singleVariant p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #4d4950;
}

.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}

.ImageCard .checkboxElement {
  display: none;
}

.ImageCard:hover {
  background: #cccace;
}
.ImageCard:hover .checkboxElement {
  display: block;
}

.addUrlModalContent {
  border-radius: 6px;
}

.ImageURLInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}
.ImageURLInput input {
  background: transparent;
  border: none;
  outline: none;
}
.ImageURLInput input:focus {
  border: none;
  outline: none;
}

/* .QuantityInput >>> .QuantityInputForm::-webkit-outer-spin-button ,
.QuantityInput >>> .QuantityInputForm::-webkit-inner-spin-button
{ 
    -webkit-appearance: block !important;
   
  } */
  .QuantityInput{
    position: relative;
  }
 

  .QtyBtn{
    position: absolute;
    top: 35px;
    right: 10px;
    z-index: 100000;
  }

  .increaseQuantityBtn,.decreaseQuantityBtn{
    font-size:7px;
    padding: 0;
    border: none;
    outline: none;
    margin: 0;
    font-weight: 500;
    cursor: pointer;
  }
</style>
